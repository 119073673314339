import React, { useEffect } from "react";
import { useAuthentication } from "../../context/authentication";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Heading,
  VStack
} from "@chakra-ui/react";
import { ServiceRecipients } from './ServiceRecipients';
import { TimerHistoryList } from './TimerHistoryList';
import { RunningTimers } from './RunningTimers';

import { FaPlayCircle } from "react-icons/fa";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";

import { useServiceRecipients, useTimers, useUserInfo } from "../../api";
import dayjs from "dayjs";

import { FirebaseError } from "firebase/app";

dayjs.extend(relativeTime);
dayjs.extend(duration);

export const Home = () => {
  const navigate = useNavigate();
  const { doLogout } = useAuthentication();

  const { data: userData } = useUserInfo();

  const { isLoading, error, data } = useServiceRecipients();
  const {
    isLoading: isLoadingTimers,
    error: errorTimers,
    data: timers,
  } = useTimers();

  useEffect(() => {
    if (error && error instanceof FirebaseError) {
      doLogout();
    } else if (errorTimers && errorTimers instanceof FirebaseError) {
      doLogout();
    }
  }, [error, errorTimers, doLogout])

  return (
    <VStack display="flex" minHeight={"100vh"}>
      <Heading size="lg" marginBottom={"1rem"} textAlign="left" width={"full"}>
        {userData ? "Hi " + userData?.first + " " + userData?.last : "Loading User"}
      </Heading>
      <Button
        leftIcon={<FaPlayCircle />}
        colorScheme="green"
        variant="solid"
        width="full"
        onClick={() => navigate("/start-timer")}
      >
        Start Timer
      </Button>

      <RunningTimers
        timers={timers}
        isLoadingTimers={isLoadingTimers}
        errorTimers={errorTimers}
        data={data}
      />

      <ServiceRecipients isLoading={isLoading} error={error} data={data} />

      <TimerHistoryList
        timers={timers}
        isLoadingTimers={isLoadingTimers}
        errorTimers={errorTimers}
        data={data}
      />;
    </VStack>
  );
};
