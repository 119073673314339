// TimerHistoryList.tsx
import React from 'react';
import { VStack, Text, List, ListItem, Box, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ServiceRecipient, Timer } from '../../api';

dayjs.extend(duration);

interface TimerHistoryListProps {
	timers: { timers: Timer[] } | undefined;
	isLoadingTimers: boolean;
	errorTimers: any;
	data: ServiceRecipient[] | undefined;
}

export const TimerHistoryList: React.FC<TimerHistoryListProps> = ({
	timers,
	isLoadingTimers,
	errorTimers,
	data,
}) => {
	return (
		<VStack width={"full"}>
			{timers && timers?.timers?.length && (
				<Text
					width="full"
					marginTop="3rem"
					marginBottom={".5rem"}
					textAlign="left"
				>
					Past Timers
				</Text>
			)}
			{!isLoadingTimers && !errorTimers && timers && (
				<List spacing={3} width="full">
					{timers?.timers?.slice(0, 3)?.map((timer) => {
						const recipient = data?.find(
							(item) => item.id === timer.service_recipient
						);

						return (
							<ListItem width={"full"} key={timer.id}>
								<Box
									borderWidth="1px"
									borderRadius="lg"
									overflow="hidden"
									padding={"0.5rem"}
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
									width={"full"}
									bgColor="gray.50"
								>
									<Flex alignItems={"center"}>
										{recipient?.first + " " + recipient?.last}
									</Flex>
									{dayjs
										.duration(
											dayjs(timer.end_time).diff(dayjs(timer.start_time))
										)
										.humanize()}
								</Box>
							</ListItem>
						);
					})}
				</List>
			)}
		</VStack>
	);
};