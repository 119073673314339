import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  Text,
  List,
  ListItem,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Switch from "react-switch";

import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";
import dayjs from "dayjs";

import {
  baseURL,
  Flag,
  Form,
  FormField,
  FormType,
  useServiceRecipients,
} from "../../api";
import axios from "axios";
import { auth } from "../../context/firebase";
import { Field } from "./Fields/Field";

export function CreateFormModal({
  onClose,
  isOpen,
  form,
  onFormSubmitted,
}: {
  isOpen: boolean;
  onClose: () => void;
  onFormSubmitted: () => void;
  form: Form | undefined;
}) {
  const toast = useToast();

  const {
    isLoading: loadingRecipients,
    error: errorRecipients,
    data: dataRecipients,
  } = useServiceRecipients();

  const recipient = dataRecipients?.find(
    (item) => item.id === form?.service_recipient_id
  );

  const [isSaving, setSaving] = useState(false)
  const [state, setState] = useState<{ [key: string]: any }>({});
  const [flagAsImportant, setFlagAsImportant] = useState(false);

  useEffect(() => {
    if (!form) return;
    if (!form?.fields) return;

    let nextState: any = {};
    form?.fields?.forEach((field) => {
      nextState[field.id.toString()] = null;
    });

    setState(nextState);
  }, [form]);

  const onChange = (id: string, val: any) => {
    setState((prev) => ({
      ...prev,
      [id]: val,
    }));
  };

  const onSubmit = async () => {
    setSaving(true)
    try {
      const token = await auth.currentUser?.getIdToken()

      const postData = {
        form_id: form?.id,
        flagged_as_important: flagAsImportant,
        fields: form?.fields.map((field) => {
          if (field.type === FormType.Signatures) {
            return {
              ...field,
              value: state[field.id]
                ?.map((item: { value: string }) => item?.value)
                ?.join("%@")
                ?.toString(),
            };
          }

          if (field.type === FormType.Multiple_Selection_List) {
            return {
              ...field,
              value: state[field.id]?.join("%@")?.toString(),
            };
          }

          return {
            ...field,
            value: state[field.id]?.toString(),
          };
        }) as FormField[],
      };

      let isValid = true;
      let fieldName = "";
      postData.fields.forEach((field) => {
        if (field.flags.includes(Flag.required) && field.value === null) {
          isValid = false;
          fieldName = field.name;
        }
      });

      if (!isValid) {
        onClose();

        toast({
          title: "Form not created.",
          description: `Field ${fieldName} is required for you to submit this form`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });

        return;
      }

      await axios.post(baseURL + "/api/forms/saveEntryWeb",
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      onClose();

      toast({
        title: "Form entry created.",
        description: "We've created your entry for you.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      onFormSubmitted();
    } catch (e) { }
    setSaving(false)
  };

  return (
    <Modal
      onClose={onClose}
      size={"xl"}
      isOpen={isOpen}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{form?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading size={"sm"}>
            {recipient?.first + " " + recipient?.last}
          </Heading>
          <Text marginBottom={"3rem"}>{form?.config?.instructions}</Text>

          <List spacing={6}>
            {form?.fields?.map((field, i) => {
              return (
                <Field
                  field={field}
                  key={i}
                  value={state[field.id]}
                  onChange={onChange}
                />
              );
            })}

            <ListItem flexDirection={"row"}>
              <Text size={"sm"} marginBottom=".5rem">
                Flag as important
              </Text>
              <Switch
                onChange={(val) => setFlagAsImportant(val)}
                checked={flagAsImportant}
              />
            </ListItem>
          </List>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit} colorScheme="blue" mr={3} disabled={isSaving}>
            {isSaving ? "Saving..." : "Create Entry"}
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}