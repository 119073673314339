import {
	Text, ListItem,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInputField,
	NumberInputStepper,
	NumberInput
} from "@chakra-ui/react";
import { Flag } from "../../../api";

export const NumberField = ({
	id, name, value, onChange, flags,
}: {
	flags: Flag[];
	id: string;
	name: string;
	value: any;
	onChange: (id: string, val: any) => void;
}) => {
	if (flags.includes(Flag.hidden_from_kiosk))
		return null;

	return (
		<ListItem>
			<Text size={"sm"} marginBottom=".5rem">
				{name}
				{flags.includes(Flag.required) && <span color="red">*</span>}
			</Text>
			<NumberInput
				defaultValue={15}
				value={value || ""}
				onChange={(valueAsString, valueAsNumber) => onChange(id, valueAsNumber)}
			>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>
		</ListItem>
	);
};
