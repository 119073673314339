// Create a new file named ServiceRecipients.tsx and add the following code:

import React from 'react';
import {
	VStack,
	Text,
	CircularProgress,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	List,
	ListItem,
	Box,
	ListIcon,
} from '@chakra-ui/react';
import { FaAngleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { ServiceRecipient } from '../../api';

interface Props {
	isLoading: boolean;
	error: any;
	data: ServiceRecipient[] | undefined;
}

export const ServiceRecipients: React.FC<Props> = ({ isLoading, error, data }) => {
	const navigate = useNavigate();

	return (
		<VStack width="full">
			<Text
				width="full"
				marginTop="3rem"
				marginBottom={".5rem"}
				textAlign="left"
			>
				Service Recipients
			</Text>
			{isLoading && (
				<CircularProgress
					isIndeterminate
					value={30}
					color="blue.400"
					thickness="12px"
				/>
			)}

			{error && (
				<Alert status="error">
					<AlertIcon />
					<AlertTitle>Failed to fetch Service Recipients!</AlertTitle>
					<AlertDescription>{error.message}</AlertDescription>
				</Alert>
			)}

			{!isLoading && !error && (
				<List spacing={3} width="full">
					{data?.map((item) => (
						<ListItem
							width={"full"}
							key={item.id}
							onClick={() => navigate("/recipient/" + item.id)}
						>
							<Box
								borderWidth="1px"
								borderRadius="lg"
								overflow="hidden"
								padding={"0.5rem"}
								display="flex"
								justifyContent="space-between"
								alignItems={"center"}
								width={"full"}
								cursor={"pointer"}
								bgColor="gray.50"
								_hover={{ bg: "gray.100" }}
							>
								{item?.first} {item?.last}{" "}
								<ListIcon as={FaAngleRight} color="gray.600" />
							</Box>
						</ListItem>
					))}
				</List>
			)}
		</VStack>
	);
};