import {
	Text,
	Textarea, ListItem
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Flag, useUserInfo } from "../../../api";
import BulletedTextArea from "../../../components/bulleted-text-area";

export const TextField = ({
	id, name, value, onChange, flags,
}: {
	flags: Flag[];
	id: string;
	name: string;
	value: any;
	onChange: (id: string, val: any) => void;
}) => {
	const { isLoading, error, data } = useUserInfo();

	useEffect(() => {
		if (flags.includes(Flag.user_id) && data?.id) {
			onChange(id, data.id);
		}

		if (flags.includes(Flag.user_name) && data?.first && data?.last) {
			onChange(id, data?.first + " " + data?.last);
		}
	}, [data]);

	if (flags.includes(Flag.hidden_from_kiosk))
		return null;

	return (
		<ListItem>
			<Text
				size={"sm"}
				marginBottom=".5rem"
				fontWeight={flags.includes(Flag.bold) ? "bold" : "normal"}
			>
				{name}
				{flags.includes(Flag.required) && <span color="red">*</span>}
			</Text>
			{flags.includes(Flag.bulleted) ? (
				<BulletedTextArea
					values={value || []}
					onChange={(e) => {
						onChange(id, e);
					}} />
			) : (
				<Textarea
					value={value || ""}
					onChange={(e) => {
						onChange(id, e.target.value);
					}}
					placeholder="Fill text here." />
			)}
		</ListItem>
	);
};
