import {
	Text, ListItem, Input,
	InputGroup,
	InputRightAddon
} from "@chakra-ui/react";
import { KeyboardEventHandler, useState } from "react";
import Creatable from "react-select/creatable";
import { Flag } from "../../../api";
import { CloseIcon } from "@chakra-ui/icons";

export const SignatureField = ({
	id, name, value, onChange, listOptions, flags,
}: {
	flags: Flag[];
	id: string;
	name: string;
	value: any;
	onChange: (id: string, val: any) => void;
	listOptions: string[];
}) => {
	const [text, setText] = useState("");

	const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
		if (!text)
			return;
		switch (event.key) {
			case "Enter":
			case "Tab":
				onChange(id, text);
				event.preventDefault();
		}
	};

	if (flags.includes(Flag.hidden_from_kiosk))
		return null;

	return (
		<ListItem>
			<Text size={"sm"} marginBottom=".5rem">
				{name}
				{flags.includes(Flag.required) && <span color="red">*</span>}
			</Text>
			<InputGroup size="md" borderColor={value ? "green.400" : "gray.200"}>
				<Input
					placeholder="Type a signature and press enter..."
					onChange={(e) => {
						setText(e.target.value);
					}}
					onKeyDown={handleKeyDown}
					value={text} />
				{text.length && (
					<InputRightAddon
						children={<CloseIcon
							onClick={() => {
								setText("");
								onChange(id, null);
							}} />} />
				)}
			</InputGroup>
		</ListItem>
	);
};
export const SignaturesField = ({
	id, name, value, onChange, listOptions, flags,
}: {
	flags: Flag[];
	id: string;
	name: string;
	value: any;
	onChange: (id: string, val: any) => void;
	listOptions: string[];
}) => {
	if (flags.includes(Flag.hidden_from_kiosk))
		return null;

	return (
		<ListItem>
			<Text size={"sm"} marginBottom=".5rem">
				{name}
				{flags.includes(Flag.required) && <span color="red">*</span>}
			</Text>
			<Creatable
				isMulti
				isClearable
				placeholder="Type a signature and press enter..."
				components={{
					DropdownIndicator: null,
				}}
				onChange={(val) => {
					onChange(id, val);
				}}
				value={value} />
		</ListItem>
	);
};
