import { Text, ListItem, theme } from "@chakra-ui/react";
import Switch from "react-switch";
import { Flag } from "../../../api";

export const TrueFalseField = ({
	id, name, value, onChange, flags,
}: {
	flags: Flag[];
	id: string;
	name: string;
	value: any;
	onChange: (id: string, val: any) => void;
}) => {
	if (flags.includes(Flag.hidden_from_kiosk))
		return null;

	return (
		<ListItem>
			<Text size={"sm"} marginBottom=".5rem">
				{name}
				{flags.includes(Flag.required) && <span color="red">*</span>}
			</Text>
			<Switch
				offColor={theme.colors.gray[200]}
				onColor={theme.colors.blue[500]}
				borderRadius={6}
				uncheckedIcon={<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						fontSize: 11,
						color: theme.colors.gray[600],
						width: "110%",
					}}
				>
					False
				</div>}
				checkedIcon={<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						fontSize: 10,
						color: theme.colors.white,
						width: "100%",
					}}
				>
					True
				</div>}
				onChange={(val) => onChange(id, val)}
				checked={!!value} />
		</ListItem>
	);
};
