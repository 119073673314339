import { useNavigate } from "react-router-dom";
import { VStack, HStack, Flex, Heading, Text, Link, Image, Code, Button } from "@chakra-ui/react";
import { ExternalLinkIcon, CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import useGeolocation from "react-hook-geolocation";

const AppleSupport = () => {
	return (
		<Link href='https://support.apple.com/en-us/102647' isExternal fontWeight="semibold">
			Apple Support
			<ExternalLinkIcon mx='2px' />
		</Link>
	)
}

export const SafariHelp = () => {
	const geolocation = useGeolocation();
	const navigate = useNavigate();

	return (
		<VStack display="flex" height="100vh">
			<Flex
				width="full"
				alignItems={"center"}
				justifyContent={"space-between"}
			>
				<Heading size="lg" textAlign="left">
					Safari Website Setting Instructions
				</Heading>
			</Flex>

			<HStack borderWidth="1px" borderRadius="md" padding="4" spacing="2" width="full">
				<Text>Location Permission Status:</Text>
				{geolocation.error && (
					<HStack spacing="1">
						<WarningIcon color="red.500" />
						<Text>Not Given</Text>
					</HStack>
				)}
				{!geolocation.error && (
					<HStack spacing="1">
						<CheckCircleIcon color="green.500" />
						<Text>Ready</Text>
					</HStack>
				)}
			</HStack>


			<VStack width="full" spacing="4" paddingBottom="20vh">
				<Text width="full">
					Safari has two settings that can prevent access to location data in the app.
				</Text>
				<Text borderWidth="1px" borderRadius="md" padding="4" width="full">
					If the setting on this page doesn't resolve the issue, follow the instructions on <AppleSupport /> to enable location services for Safari.
				</Text>
				<Text width="full">
					While on this website, tap the <Code>Aa</Code> button next to the URL bar.
				</Text>
				<Image src="/img/safari-help-1.webp" width="80%" alt="A safari page opened to the start timer page and the Aa button highlighted with a red marking." />
				<Text width="full">
					Tap the <Code>Website Settings</Code> button to navigate to the website's settings.
				</Text>
				<Image src="/img/safari-help-2.webp" width="80%" alt="Safari page with the Aa menu opened and Website Settings highlighted with a red marking." />
				<Text width="full">
					Finally, tap the <Code>Location</Code> button and make sure the <Code>Allow</Code> option is selected.
				</Text>
				<Image src="/img/safari-help-3.webp" width="80%" alt="Website settings screen with location permission set to Allow and highlighted with a red marking." />
				<Text>
					If "Location Permission Status" is still "Not Given" after refreshing, ensure Safari has location access using the instructions at <AppleSupport />.
				</Text>
				<Text width="full">
					Otherwise, <Button variant='link'
						textDecoration="underline"
						color={"text"}
						fontWeight="normal"
						onClick={() => navigate(-1)}>Go Back To Timers</Button>
				</Text>
			</VStack>
		</VStack >
	)
}