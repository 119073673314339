import {
	Text, ListItem,
	Stack,
	Checkbox,
	CheckboxGroup
} from "@chakra-ui/react";
import { Flag } from "../../../api";
import { xor } from "lodash";

export const MultiListField = ({
	id, name, value, onChange, listOptions, flags,
}: {
	flags: Flag[];
	id: string;
	name: string;
	value: any;
	onChange: (id: string, val: any) => void;
	listOptions: string[];
}) => {
	const handleChange = (option: string) => {
		const next = xor(value, [option]);
		onChange(id, next);
	};

	if (flags.includes(Flag.hidden_from_kiosk))
		return null;

	return (
		<ListItem>
			<Text size={"sm"} marginBottom=".5rem">
				{name}
				{flags.includes(Flag.required) && <span color="red">*</span>}
			</Text>
			<CheckboxGroup>
				<Stack>
					{listOptions?.map((option) => (
						<Checkbox
							size="md"
							value={option}
							colorScheme="blue"
							key={option + "multi"}
							onChange={() => handleChange(option)}
						>
							{option}
						</Checkbox>
					))}
				</Stack>
			</CheckboxGroup>
		</ListItem>
	);
};
