import { useState, useEffect } from "react";
import { useAuthentication } from "../../context/authentication";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Heading,
  VStack,
  Box,
  Text,
  List,
  ListItem,
  CircularProgress,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  UnorderedList,
} from "@chakra-ui/react";
import { SwitchField } from "../../components/SwitchField";

import { Flag, FormType, useForm, useFormEntries } from "../../api";
import { EditIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";

import localizedFormat from "dayjs/plugin/localizedFormat";
import { CreateFormModal } from "./create-form-modal";
import { FaFlag } from "react-icons/fa";

import { FirebaseError } from "firebase/app";

dayjs.extend(localizedFormat);

export const Forms = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const { doLogout } = useAuthentication();
  const params = useParams();

  const { isLoading, error, data } = useForm(params.form || "");
  const {
    isLoading: isLoadingEntries,
    error: errorEntries,
    data: entries,
    refetch: refetchEntries,
  } = useFormEntries(params.form || "");

  useEffect(() => {
    if (error && error instanceof FirebaseError) {
      doLogout();
    } else if (errorEntries && errorEntries instanceof FirebaseError) {
      doLogout();
    }
  }, [error, errorEntries, doLogout])

  const dateMap: any = {};

  return (
    <VStack paddingBottom={"12rem"}>
      <Heading size="lg" marginBottom={"1rem"} width="full">
        {data?.name}
      </Heading>
      <Button
        leftIcon={<EditIcon />}
        colorScheme="blue"
        variant="solid"
        width="full"
        onClick={() => setOpen(true)}
      >
        New Entry
      </Button>

      <VStack width={"full"}>
        {isLoading && (
          <CircularProgress
            isIndeterminate
            value={30}
            color="blue.400"
            thickness="12px"
          />
        )}

        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Failed to fetch form!</AlertTitle>
            <AlertDescription>{error.message}</AlertDescription>
          </Alert>
        )}

        {!isLoading && !error && (
          <List spacing={3} width="full" marginTop="1rem">
            {isLoadingEntries && (
              <CircularProgress
                isIndeterminate
                value={30}
                color="blue.400"
                thickness="12px"
              />
            )}

            {entries?.map((item) => {
              const day = dayjs(item?.created_at).format("DD/MM/YYYY");

              if (!dateMap[day]) {
                dateMap[day] = item.id;
              }

              return (
                <ListItem
                  width={"full"}
                  key={item.id}
                  onClick={() =>
                    navigate("/forms/" + params.form + "/details/" + item?.id)
                  }
                >
                  <Box>
                    {dateMap[day] && dateMap[day] === item.id && (
                      <Text color={"gray.500"}>
                        {dayjs(item?.created_at).format("MM/DD/YYYY")}
                      </Text>
                    )}
                    <Text>{dayjs(item?.created_at).format("LT")}</Text>
                    {item?.flagged_as_important && (
                      <Box color="yellow.400">
                        <FaFlag />
                      </Box>
                    )}
                    <List
                      spacing={3}
                      borderRadius="lg"
                      padding={"0.5rem"}
                      width={"full"}
                      borderWidth="1px"
                      cursor={"pointer"}
                      _hover={{ bg: "gray.50" }}
                    >
                      {item?.fields?.slice(0, 3).map((field, item) => {
                        if (field?.flags?.includes(Flag.hidden_from_list) ||
                          field.type === FormType.Header ||
                          field.type === FormType.Separator) {
                          return null
                        }


                        if (
                          field.type === FormType.Signatures ||
                          field.type === FormType.List ||
                          field.type === FormType.Multiple_Selection_List
                        ) {
                          return (
                            <ListItem key={field?.id}>
                              <Text fontWeight={"bold"}>{field?.name}</Text>
                              <UnorderedList>
                                {field?.value
                                  ?.split(field?.value.includes(",") ? "," : "%@")
                                  .map((val) => (
                                    <ListItem key={val}>{val}</ListItem>
                                  ))}
                              </UnorderedList>
                            </ListItem>
                          );
                        }

                        if (
                          field.type === FormType.True_False ||
                          field.type === FormType.Yes_No
                        ) {
                          return (
                            <SwitchField
                              key={field?.id}
                              name={field?.name}
                              value={field?.value}
                              flags={field?.flags}
                            />
                          );
                        }

                        if (field.type === FormType.Date) {
                          let date = dayjs(field.value)

                          return (
                            <ListItem key={field?.id}>
                              <Text fontWeight={"bold"}>{field?.name}</Text>
                              <Text>{date.format("MMM D YYYY, h:mm a") || "Empty"}</Text>
                            </ListItem>
                          )
                        }

                        return (
                          <ListItem key={field?.id}>
                            <Text fontWeight={"bold"}>{field?.name}</Text>
                            <Text>{field?.value || "Empty"}</Text>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        )}
        <CreateFormModal
          isOpen={open}
          onClose={() => setOpen(false)}
          form={data}
          onFormSubmitted={() => {
            refetchEntries();
          }}
        />
      </VStack>
    </VStack>
  );
};
