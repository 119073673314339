import axios from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { auth } from "../context/firebase";

// export const baseURL = "http://localhost:4000"
export const baseURL = "https://sss.windchillmedia.com"

export type ServiceRecipient = {
  first: string;
  last: string;
  id: string;
};

export enum FormType {
  Text = "Text",
  Number = "Number",
  True_False = "True/False",
  Yes_No = "Yes/No",
  Date = "Date",
  List = "List",
  Multiple_Selection_List = "Multiple Selection List",
  Signature = "Signature",
  Signatures = "Signatures",
  Header = "Header",
  Separator = "Separator",
}

export enum Flag {
  default = "default",
  user_id = "user_id",
  user_name = "user_name",
  hidden_from_kiosk = "hidden_from_kiosk",
  hidden_from_list = "hidden_from_list",
  not_kiosk_editable = "not_kiosk_editable",
  staff_picker = "staff_picker",
  sr_picker = "sr_picker",
  required = "required",
  bold = "bold",
  bulleted = "bulleted",
  left_align_true_false = "left_align_true_false",
  align_with_text = "align_with_text",
  show_label_in_list = "show_label_in_list",
}

export type FormField = {
  id: string;
  value: string;
  flags: Flag[];
  name: string;
  type: FormType;
  listOptions: string[];
  defaultValue: any;
};

export type Timer = {
  id: number;
  service_recipient: string;
  service_line_id: number;
  start_time: string;
  start_latitude: number;
  start_longitude: number;
  end_time: string;
  end_latitude: number;
  end_longitude: number;
  notes: string;
};

export function useUserInfo() {
  return useQuery<
    { first: string; last: string; id: string; auth_id: string },
    Error
  >("userInfo", async () => {
    const token = await auth.currentUser?.getIdToken()

    const res = await axios.get(baseURL + "/api/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });
}

export function useServiceRecipients(): UseQueryResult<ServiceRecipient[], Error> {
  return useQuery<ServiceRecipient[], Error>(
    "serviceRecipients",
    async () => {
      const token = await auth.currentUser?.getIdToken()

      const res = await axios.get(
        baseURL + "/api/serviceRecipients",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data.sort((a: ServiceRecipient, b: ServiceRecipient) => a.first.localeCompare(b.first));
    }
  );
}

export function useForms(serviceRecipientID: string) {
  return useQuery<{ id: string; name: string }[], Error>(["forms", serviceRecipientID], async () => {
    const token = await auth.currentUser?.getIdToken()

    const res = await axios.get(
      baseURL + `/api/serviceRecipients/${serviceRecipientID}/forms`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data.sort((a: { id: string; name: string }, b: { id: string; name: string }) => a.name.localeCompare(b.name));
  });
}

export interface Form {
  id: string;
  name: string;
  service_recipient_id: string;
  fields: FormField[];
  config: {
    compact_display: string;
    instructions: string;
    sort_by: string;
  };
}

export function useForm(formID: string) {
  return useQuery<Form, Error>(["form", formID], async () => {
    const token = await auth.currentUser?.getIdToken()

    const res = await axios.get(
      baseURL + `/api/forms/${formID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  });
}

export function useFormEntries(formID: string) {
  return useQuery<
    {
      id: number;
      fields: [FormField];
      form_id: number;
      service_recipient_id: string;
      service_recipient_name: string;
      user_id: string;
      user_name: string;
      flagged_as_important: boolean;
      created_at: string;
      updated_at: string;
      deleted_at: string;
    }[],
    Error
  >(["entries", formID], async () => {
    const token = await auth.currentUser?.getIdToken()

    const res = await axios.get(
      baseURL + `/api/forms/${formID}/getEntries`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  });
}

export function useTimerOptions() {
  return useQuery<
    {
      /* Service Recipient info */
      id: string;
      name: string;
      service_lines: {
        id: number;
        procedure_description: string;
      }[];
    }[],
    Error
  >("timerOptions", async () => {
    const token = await auth.currentUser?.getIdToken()

    const res = await axios.get(
      baseURL + "/api/timerOptions",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  });
}

export function useTimers() {
  return useQuery<
    {
      running_timers: [Timer];
      timers: [Timer];
    },
    Error
  >("timers", async () => {
    const token = await auth.currentUser?.getIdToken()

    const res = await axios.get(baseURL + "/api/timers", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });
}

export function useTimer(id: string) {
  return useQuery<Timer, Error>("timer", async () => {
    const token = await auth.currentUser?.getIdToken()

    const res = await axios.get(
      baseURL + `/api/timers/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  });
}
