import {
	Text, ListItem,
	FormControl
} from "@chakra-ui/react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { Flag } from "../../../api";
import dayjs from "dayjs";

export const DateField = ({
	id, name, value, onChange, flags,
}: {
	flags: Flag[];
	id: string;
	name: string;
	value: any;
	onChange: (id: string, val: any) => void;
}) => {
	useEffect(() => {
		if (id)
			onChange(id, (new Date()).toISOString());
	}, []);

	if (flags.includes(Flag.hidden_from_kiosk))
		return null;

	const parsedDate = value ? dayjs(value).toDate() : new Date();

	return (
		<ListItem>
			<Text size={"sm"} marginBottom=".5rem">
				{name}
				{flags.includes(Flag.required) && <span color="red">*</span>}
			</Text>
			<FormControl>
				<DatePicker
					showTimeSelect
					selected={parsedDate}
					onChange={(date: Date) => onChange(id, date.toISOString())}
					showPopperArrow={true}
					dateFormat="Pp" />
			</FormControl>
		</ListItem>
	);
};
