import { useNavigate, useParams } from "react-router-dom";
import {
  Heading,
  VStack,
  Text,
  RadioGroup,
  Stack,
  Radio,
  Button,
  Textarea,
  Box,
  Flex,
  theme,
  useToast,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  HStack,
  Image,
  Link
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import GoogleMapReact from "google-map-react";

import { baseURL, useTimer, useTimerOptions } from "../../api";
import { useEffect, useState } from "react";
import { FaMapMarker, FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import useGeolocation from "react-hook-geolocation";
import axios from "axios";
import { find, first } from "lodash";
import dayjs from "dayjs";
import { auth } from "../../context/firebase";

function iOS(): Boolean {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac"))
}

export const Timer = ({ start }: { start?: boolean }) => {
  const geolocation = useGeolocation();
  const navigate = useNavigate();
  const { id = "" } = useParams();

  const toast = useToast();

  const [recipientId, setRecipientId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [notes, setNotes] = useState("");
  const [location, setLocation] = useState({
    lat: geolocation.latitude,
    lng: geolocation.longitude,
  });

  const { data: timerOptions } = useTimerOptions();
  const { data: timer } = useTimer(id);

  useEffect(() => {
    if (!id || !timer?.id || start) return;

    setNotes(timer.notes);
    setTypeId(timer.service_line_id.toString());
    setRecipientId(timer.service_recipient);
  }, [timer, id, start]);

  useEffect(() => {
    setLocation({
      lat: geolocation.latitude,
      lng: geolocation.longitude,
    });
  }, [geolocation]);

  useEffect(() => {
    if (!start) return;

    setRecipientId(first(timerOptions)?.id || "");
  }, [timerOptions, start]);

  useEffect(() => {
    if (!start) return;

    setTypeId(
      first(
        find(timerOptions, ["id", recipientId])?.service_lines
      )?.id?.toString() || ""
    );
  }, [recipientId, timerOptions, start]);

  const submit = async () => {
    try {
      if (!location?.lat) {
        toast({
          title: "Missing Location",
          description: "Please add your current location to start your timer",
          status: "error",
          duration: 4000,
          isClosable: true,
        });

        return;
      }

      const token = await auth.currentUser?.getIdToken()

      if (start) {
        await axios.post(baseURL + "/api/timers/new",
          {
            service_recipient: recipientId,
            service_line_id: Number(typeId),
            start_latitude: location.lat,
            start_longitude: location.lng,
            notes: notes,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await axios.post(baseURL + `/api/timers/${id}/end`,
          {
            service_recipient: recipientId,
            service_line_id: Number(typeId),
            end_latitude: location.lat,
            end_longitude: location.lng,
            notes: notes,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      navigate("/");

      toast({
        title: "Timer entry created.",
        description: "We've created your timer for you.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e: any) {
      if (e?.response?.data) {
        toast({
          title: e?.response.data?.error,
          description: e?.response.data?.error_message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      console.log(e);
    }
  };

  return (
    <VStack display="flex" height={"100vh"}>
      <Flex
        width={"full"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Heading size="lg" textAlign="left">
          {start ? "Start" : "End"} Timer
        </Heading>

        {!start && timer && (
          <Heading size={"md"}>
            {dayjs.duration(dayjs().diff(dayjs(timer?.start_time))).humanize()}
          </Heading>
        )}
      </Flex>

      <VStack width={"full"}>
        <Flex
          direction="column"
          alignItems="baseline"
          justifyContent="start"
          width="full"
        >
          <Text
            width="full"
            marginTop="1rem"
            marginBottom={".5rem"}
            textAlign="left"
          >
            Service Recipients
          </Text>

          {(timerOptions == null || timerOptions?.length == 0) && (
            <Text borderWidth="1px" borderRadius="md" padding="4" width="full">No Available Service Recipients</Text>
          )}
          <RadioGroup
            onChange={(val) => setRecipientId(val)}
            value={recipientId}
          >
            <Stack>
              {timerOptions?.map((item) => {
                return (
                  <Radio width={"full"} key={item.id} value={item.id}>
                    {item.name}
                  </Radio>
                );
              })}
            </Stack>
          </RadioGroup>
        </Flex>

        {(timerOptions && timerOptions?.length > 0) && (
          <Flex
            direction="column"
            alignItems="baseline"
            justifyContent="start"
            width="full"
          >
            <Text
              width="full"
              marginTop="1rem"
              marginBottom={".5rem"}
              textAlign="left"
            >
              Service Type
            </Text>

            <RadioGroup onChange={(val) => setTypeId(val)} value={typeId}>
              <Stack>
                {timerOptions
                  ?.find((item) => item.id === recipientId)
                  ?.service_lines?.map((item) => {
                    return (
                      <Radio
                        width={"full"}
                        key={item.id}
                        value={item.id.toString()}
                      >
                        {item.procedure_description}
                      </Radio>
                    );
                  })}
              </Stack>
            </RadioGroup>
          </Flex>
        )}

        <VStack width="full" paddingTop="16px">
          {geolocation.error && (
            <VStack>
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>{geolocation.error.message}</AlertTitle>
                <AlertDescription>
                  Please update your settings to allow location sharing
                </AlertDescription>
              </Alert>
              {iOS() && (
                <VStack
                  borderWidth="1px"
                  borderRadius="md"
                  padding="4"
                  spacing="4"
                >
                  <HStack>
                    <Image src="/img/icon_safari.webp" alt="Safari Icon" boxSize="32px" />
                    <Text>
                      Using Safari? Ensure <b><u>Both</u></b> Location Settings Are Enabled
                    </Text>
                  </HStack>
                  <Button
                    variant='link'
                    textDecoration="underline"
                    color={"text"}
                    fontWeight="normal"
                    onClick={() => navigate('/safari-help')}
                  >
                    Website-Specific Setting
                    <ExternalLinkIcon mx='2px' />
                  </Button>
                  <Link href='https://support.apple.com/en-us/102647' isExternal textDecoration="underline">
                    Location Services Setting
                    <ExternalLinkIcon mx='2px' />
                  </Link>
                </VStack>
              )}
            </VStack>
          )}

          {!geolocation.error && (
            <Box width={"full"} height={300} position="relative">
              <Button
                colorScheme="gray"
                variant="solid"
                width="fit-content"
                borderWidth={1}
                shadow="md"
                style={{
                  margin: "0 auto",
                  position: "absolute",
                  top: 0,
                  zIndex: 1,
                }}
                onClick={() => {
                  setLocation({
                    lat: geolocation.latitude,
                    lng: geolocation.longitude,
                  });
                }}
              >
                Current Location
              </Button>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDhx81FVMbOyTG5LWazJMihEQkpx8022fM",
                }}
                center={location}
                onChange={(value) => {
                  setLocation({
                    lat: value.center.lat,
                    lng: value.center.lng,
                  });
                }}
                defaultZoom={17}
              >
                <FaMapMarker size={18} color={theme.colors.red[400]} />
              </GoogleMapReact>
            </Box>
          )}
        </VStack>

        <Textarea
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          placeholder="Additional Notes (Optional)"
        />

        <Stack width="full" spacing="3">
          <Button
            leftIcon={start ? <FaPlayCircle /> : <FaPauseCircle />}
            colorScheme={start ? "green" : "red"}
            variant="solid"
            width="full"
            onClick={submit}
            disabled={!!geolocation.error || (timerOptions == null || timerOptions.length == 0) || recipientId.length == 0 || isNaN(Number(typeId))}
          >
            {start ? "Start" : "End"} Timer
          </Button>

          <Button
            colorScheme="gray"
            variant="solid"
            width="full"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </Stack>
      </VStack>
    </VStack>
  );
};
