import {
	Text, ListItem,
	Radio,
	Stack,
	RadioGroup
} from "@chakra-ui/react";
import { Flag } from "../../../api";

export const ListField = ({
	id, name, value, onChange, listOptions, flags,
}: {
	flags: Flag[];
	id: string;
	name: string;
	value: any;
	onChange: (id: string, val: any) => void;
	listOptions: string[];
}) => {
	if (flags.includes(Flag.hidden_from_kiosk))
		return null;

	return (
		<ListItem>
			<Text size={"sm"} marginBottom=".5rem">
				{name}
				{flags.includes(Flag.required) && <span color="red">*</span>}
			</Text>
			<RadioGroup
				onChange={(val) => onChange(id, val.toString())}
				value={value}
			>
				<Stack>
					{listOptions?.map((option) => (
						<Radio size="md" value={option} colorScheme="blue" key={option}>
							{option}
						</Radio>
					))}
				</Stack>
			</RadioGroup>
		</ListItem>
	);
};
