// RunningTimers.tsx
import React from 'react';
import { useNavigate } from "react-router-dom";
import {
	VStack,
	Text,
	CircularProgress,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	List,
	ListItem,
	Box,
	Flex,
} from '@chakra-ui/react';
import { FaPauseCircle } from 'react-icons/fa';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ServiceRecipient, Timer } from '../../api';
dayjs.extend(duration);

interface Props {
	timers: {
		running_timers: [Timer];
		timers: [Timer];
	} | undefined;
	isLoadingTimers: boolean;
	errorTimers: any,
	data: ServiceRecipient[] | undefined
}

export const RunningTimers: React.FC<Props> = ({
	timers,
	isLoadingTimers,
	errorTimers,
	data
}) => {
	const navigate = useNavigate();
	return <VStack width={"full"}>
		{timers?.running_timers?.length && (
			<Text
				width="full"
				marginTop="3rem"
				marginBottom={".5rem"}
				textAlign="left"
			>
				Running Timers
			</Text>
		)}
		{isLoadingTimers && (
			<CircularProgress
				isIndeterminate
				value={30}
				color="blue.400"
				thickness="12px"
			/>
		)}

		{errorTimers && (
			<Alert status="error">
				<AlertIcon />
				<AlertTitle>Failed to fetch running Timers!</AlertTitle>
				<AlertDescription>{errorTimers.message}</AlertDescription>
			</Alert>
		)}

		{!isLoadingTimers && !errorTimers && (
			<List spacing={3} width="full">
				{timers?.running_timers?.map((timer) => {
					const recipient = data?.find(
						(item) => item.id === timer.service_recipient
					);

					return (
						<ListItem
							width={"full"}
							key={timer.id}
							onClick={() => navigate("/end-timer/" + timer.id)}
						>
							<Box
								borderWidth="1px"
								borderRadius="lg"
								overflow="hidden"
								padding={"0.5rem"}
								display="flex"
								justifyContent="space-between"
								alignItems={"center"}
								width={"full"}
								cursor={"pointer"}
								bgColor="gray.50"
								_hover={{ bg: "gray.100" }}
							>
								<Flex alignItems={"center"}>
									<FaPauseCircle
										color="gray.600"
										size={20}
										style={{ marginRight: 5 }}
									/>
									{recipient?.first + " " + recipient?.last}
								</Flex>
								{dayjs
									.duration(dayjs().diff(dayjs(timer.start_time)))
									.humanize()}
							</Box>
						</ListItem>
					);
				})}
			</List>
		)}
	</VStack>
}