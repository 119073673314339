import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RootProvider } from "./rootProviders";
import { App } from "./App";

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ChakraProvider>
    <QueryClientProvider client={queryClient}>
      <RootProvider>
        <App />
      </RootProvider>
    </QueryClientProvider>
  </ChakraProvider>
);
