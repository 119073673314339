import { useEffect } from "react";
import { useAuthentication } from "../../context/authentication";
import { useParams } from "react-router-dom";
import {
  Heading,
  VStack,
  Box,
  Text,
  List,
  ListItem,
  CircularProgress,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Divider,
  UnorderedList
} from "@chakra-ui/react";
import { FaFlag } from "react-icons/fa";
import { SwitchField } from "../../components/SwitchField";

import { Flag, FormType, useForm, useFormEntries } from "../../api";
import dayjs from "dayjs";

import localizedFormat from "dayjs/plugin/localizedFormat";

import { FirebaseError } from "firebase/app";

dayjs.extend(localizedFormat);

export const FormDetail = () => {
  const { doLogout } = useAuthentication();
  const params = useParams();

  const { isLoading, error, data } = useForm(params.form || "");
  const {
    data: entries,
  } = useFormEntries(params.form || "");

  useEffect(() => {
    if (error && error instanceof FirebaseError) {
      doLogout();
    }
  }, [error, doLogout])

  const entry = entries?.find((item) => item?.id?.toString() === params.entry);

  return (
    <VStack paddingBottom={"12rem"}>
      <Heading size="lg" width="full">
        {data?.name}
      </Heading>
      <Text width="full" fontWeight={"bold"}>
        {entry?.service_recipient_name}
      </Text>
      <Text width="full">
        {dayjs(entry?.created_at).format("MM/DD/YYYY LT")}
      </Text>
      <Divider orientation="horizontal" />

      {entry?.flagged_as_important && (
        <>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"start"}
            width="full"
          >
            <Box color="yellow.400">
              <FaFlag />
            </Box>

            <Text marginLeft="1rem" width="full">
              Flagged As Important
            </Text>
          </Box>
          <Divider orientation="horizontal" />
        </>
      )}
      <VStack width={"full"}>
        {isLoading && (
          <CircularProgress
            isIndeterminate
            value={30}
            color="blue.400"
            thickness="12px"
          />
        )}

        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Failed to fetch entry!</AlertTitle>
            <AlertDescription>{error.message}</AlertDescription>
          </Alert>
        )}

        {!isLoading && !error && (
          <List spacing={"2rem"} width={"full"} marginTop={"1rem"}>
            {entry?.fields?.map((field, item) => {
              if (field?.flags?.includes(Flag.hidden_from_list)) return null;

              if (field.type === FormType.Header) {
                return <Heading fontWeight={"bold"}>{field?.name}</Heading>;
              }

              if (field.type === FormType.Separator) {
                return <Divider orientation="horizontal" />;
              }

              if (
                field.type === FormType.Signatures ||
                field.type === FormType.List ||
                field.type === FormType.Multiple_Selection_List
              ) {
                return (
                  <ListItem key={field?.id}>
                    <Text fontWeight={"bold"}>{field?.name}</Text>
                    <UnorderedList>
                      {field?.value
                        ?.split(field?.value.includes(",") ? "," : "%@")
                        .map((val) => (
                          <ListItem key={val}>{val}</ListItem>
                        ))}
                    </UnorderedList>
                  </ListItem>
                );
              }

              if (
                field.type === FormType.True_False ||
                field.type === FormType.Yes_No
              ) {
                return (
                  <SwitchField
                    key={field?.id}
                    name={field?.name}
                    value={field?.value}
                    flags={field?.flags}
                  />
                );
              }

              if (field.type === FormType.Date) {
                let date = dayjs(field.value)

                return (
                  <ListItem key={field?.id}>
                    <Text fontWeight={"bold"}>{field?.name}</Text>
                    <Text>{date.format("MMM D YYYY, h:mm a") || "Empty"}</Text>
                  </ListItem>
                )
              }
              return (
                <ListItem key={field?.id}>
                  <Text fontWeight={"bold"}>{field?.name}</Text>
                  <Text>{field?.value || "Empty"}</Text>
                </ListItem>
              );
            })}
          </List>
        )}
      </VStack>
    </VStack>
  );
};