import { useEffect } from "react";
import { useAuthentication } from "../../context/authentication";
import { useNavigate, useParams } from "react-router-dom";
import {
  Heading,
  VStack,
  Box,
  Text,
  List,
  ListItem,
  ListIcon,
  CircularProgress,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
} from "@chakra-ui/react";

import { FaAngleRight, FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { useForms, useServiceRecipients, useTimers } from "../../api";
import { find } from "lodash";

import { FirebaseError } from "firebase/app";

export const Recipient = () => {
  const navigate = useNavigate();
  const { doLogout } = useAuthentication();
  const { recipient: recipientId } = useParams();

  const { data: timers } = useTimers();

  const {
    error: errorRecipients,
    data: dataRecipients
  } = useServiceRecipients();

  const {
    isLoading: isLoadingForms,
    error: errorForms,
    data: dataForms
  } = useForms(recipientId ?? "");

  useEffect(() => {
    if (errorRecipients && errorRecipients instanceof FirebaseError) {
      doLogout();
    } else if (errorForms && errorForms instanceof FirebaseError) {
      doLogout();
    }
  }, [errorRecipients, errorForms, doLogout])

  const recipient = dataRecipients?.find(
    (item) => item.id === recipientId
  );

  const timer = find(timers?.running_timers, [
    "service_recipient",
    recipientId,
  ]);

  return (
    <VStack display="flex" minHeight={"100vh"}>
      <Heading size="lg" textAlign="left" width={"full"} marginBottom={"1rem"}>
        {recipient?.first} {recipient?.last}
      </Heading>

      {timer ? (
        <Button
          leftIcon={<FaPauseCircle />}
          colorScheme="red"
          variant="solid"
          width="full"
          paddingTop="12px"
          paddingBottom="12px"
          onClick={() => navigate("/end-timer/" + timer?.id)}
        >
          End Timer
        </Button>
      ) : (
        <Button
          leftIcon={<FaPlayCircle />}
          colorScheme="green"
          variant="solid"
          width="full"
          paddingTop="12px"
          paddingBottom="12px"
          onClick={() => navigate("/start-timer")}
        >
          Start Timer
        </Button>
      )}

      <VStack width={"full"} paddingBottom="25vh">
        <Text
          width="full"
          marginTop="1rem"
          marginBottom={".5rem"}
          textAlign="left"
        >
          Forms
        </Text>
        {isLoadingForms && (
          <CircularProgress
            isIndeterminate
            value={30}
            color="blue.400"
            thickness="12px"
          />
        )}

        {errorForms && (
          <Alert status="error">
            <AlertIcon />
            <Box>
              <AlertTitle>Failed to fetch forms!</AlertTitle>
              <AlertDescription>{errorForms.message}</AlertDescription>
            </Box>
          </Alert>
        )}

        {!isLoadingForms && !errorForms && (
          <List spacing={3} width="full">
            {dataForms?.map((item) => {
              return (
                <ListItem
                  width={"full"}
                  key={item.id}
                  onClick={() => navigate("/forms/" + item.id)}
                >
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    padding={"0.5rem"}
                    display="flex"
                    justifyContent="space-between"
                    alignItems={"center"}
                    width={"full"}
                    cursor={"pointer"}
                    bgColor="gray.50"
                    _hover={{ bg: "gray.100" }}
                  >
                    {item?.name}
                    <ListIcon as={FaAngleRight} color="gray.600" />
                  </Box>
                </ListItem>
              );
            })}
          </List>
        )}
      </VStack>
    </VStack>
  );
};
