import React, { createContext, useContext, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, User, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCQoH8Chq5CrAObRk1EsXXpveBRh7K4CzE",
  authDomain: "winterfamilycare.firebaseapp.com",
  databaseURL: "https://winterfamilycare.firebaseio.com",
  projectId: "winterfamilycare",
  storageBucket: "winterfamilycare.appspot.com",
  messagingSenderId: "700160120144",
  appId: "1:700160120144:web:0e89173d68bdfd8ac34855"
};


const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

type TrackingProviderProps = {
  children: React.ReactNode;
};

type doUserLoginOnFirebaseType = (
  email: string,
  password: string
) => Promise<void>;
type logoutUserFromFirebaseType = () => Promise<void>;

type FirebaseState = {
  user: User | null;
  isFetchingUser: boolean;

  doUserLoginOnFirebase: doUserLoginOnFirebaseType;
  logoutUserFromFirebase: logoutUserFromFirebaseType;
};

const FirebaseContext = createContext<FirebaseState | undefined>(undefined);

function FirebaseProvider({ children }: TrackingProviderProps) {

  // AUTHENTICATION
  const [user, setUser] = useState<User | null>(null);
  const [isFetchingUser, setIsFetchingUser] = useState(true);

  auth.onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }

    setIsFetchingUser(false);
  });

  const doUserLoginOnFirebase: doUserLoginOnFirebaseType = (email, password) =>
    new Promise(async (resolve, reject) => {
      try {
        await signInWithEmailAndPassword(auth, email, password);

        resolve();
      } catch (e) {
        reject(e);
      }
    });

  const logoutUserFromFirebase = async () => await auth.signOut();

  return (
    <FirebaseContext.Provider
      value={{
        doUserLoginOnFirebase,
        logoutUserFromFirebase,
        user,
        isFetchingUser,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}

function useFirebase() {
  const context = useContext(FirebaseContext);

  if (context === undefined) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }

  return context;
}

export { FirebaseProvider, useFirebase, auth };
