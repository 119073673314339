import { Heading, Divider } from "@chakra-ui/react";
import { useEffect } from "react";
import {
	Flag, FormField,
	FormType
} from "../../../api";
import { TextField } from "./TextField";
import { NumberField } from "./NumberField";
import { TrueFalseField } from "./TrueFalseField";
import { YesNoField } from "./YesNoField";
import { DateField } from "./DateField";
import { ListField } from "./ListField";
import { MultiListField } from "./MultiListField";
import { SignatureField, SignaturesField } from "./SignatureField";

export const Field = ({
	field, value, onChange,
}: {
	field: FormField;
	value: any;
	onChange: (id: string, val: any) => void;
}) => {
	useEffect(() => {
		if (field?.flags?.includes(Flag.default) && field?.type !== FormType.Date) {
			onChange(field?.id, field?.defaultValue);
		}
	}, [field?.id]);

	switch (field.type) {
		case FormType.Header:
			return (
				<Heading fontWeight={"bold"} mt="4">
					{field?.name}
				</Heading>
			);

		case FormType.Separator:
			return <Divider orientation="horizontal" />;

		case FormType.Text:
			return (
				<TextField
					{...field}
					value={value}
					onChange={field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange} />
			);
		case FormType.Number:
			return (
				<NumberField
					{...field}
					value={value}
					onChange={field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange} />
			);
		case FormType.True_False:
			return (
				<TrueFalseField
					{...field}
					value={value}
					onChange={field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange} />
			);
		case FormType.Yes_No:
			return (
				<YesNoField
					{...field}
					value={value}
					onChange={field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange} />
			);
		case FormType.Date:
			return (
				<DateField
					{...field}
					value={value}
					onChange={field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange} />
			);
		case FormType.List:
			return (
				<ListField
					{...field}
					value={value}
					onChange={field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange} />
			);
		case FormType.Multiple_Selection_List:
			return (
				<MultiListField
					{...field}
					value={value}
					onChange={field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange} />
			);
		case FormType.Signature:
			return (
				<SignatureField
					{...field}
					value={value}
					onChange={field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange} />
			);
		case FormType.Signatures:
			return (
				<SignaturesField
					{...field}
					value={value}
					onChange={field.flags.includes(Flag.not_kiosk_editable) ? () => { } : onChange} />
			);
		default:
			return null;
	}
};
