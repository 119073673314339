import { Navigate, Routes as Switch, Route, useLocation, useNavigate } from "react-router-dom";
import { Container, Text, VStack } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";

import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { useAuthentication } from "./context/authentication";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Forms } from "./pages/Forms";
import { Recipient } from "./pages/Recipient";
import { FormDetail } from "./pages/FormDetail";
import { Timer } from "./pages/Timer";
import { SafariHelp } from "./pages/SafariHelp";
import { useEffect, useState } from "react";
import { queryClient } from ".";

export const App = () => {
  const { isFetchingUser, isLogged } = useAuthentication();

  if (!isLogged && isFetchingUser) return <h1>Loading user...</h1>;

  return isLogged ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

const AuthenticatedApp = () => {
  const { isLogged } = useAuthentication();
  let location = useLocation();

  if (!isLogged) return <Navigate to="/login" state={{ from: location }} replace />;

  return (
    <Navigation>
      <Switch>
        <Route path="/" element={<Home />} />
        <Route path="/forms/:form" element={<Forms />} />
        <Route path="/forms/:form/details/:entry" element={<FormDetail />} />
        <Route path="/recipient/:recipient" element={<Recipient />} />
        <Route path="/start-timer" element={<Timer start />} />
        <Route path="/end-timer/:id" element={<Timer />} />
        <Route path="/safari-help" element={<SafariHelp />} />
        <Route path="*">Error 404 - Page not found!</Route>
      </Switch>
    </Navigation>
  );
};

const UnauthenticatedApp = () => {
  return (
    <Switch>
      {["/", "/login"].map((item) => {
        return <Route path={item} key={item} element={<Login />} />;
      })}

      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Switch>
  );
};

const Navigation = (props: { children: any }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { doLogout } = useAuthentication();

  const logout = async () => {
    await doLogout();
    queryClient.clear()

    navigate("/login");
  };

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <VStack paddingTop={isMobile ? "0px" : "6rem"}>
      <Container maxW="md">
        {location.pathname === "/" && (
          <Text
            onClick={logout}
            color="blue.500"
            marginTop="1rem"
            textAlign="right"
            cursor="pointer"
            marginBottom={"2rem"}
          >
            Sign Out
          </Text>
        )}
        {location.pathname !== "/" && (
          <Text
            onClick={() => navigate(-1)}
            color="blue.500"
            marginTop="1rem"
            textAlign="left"
            cursor="pointer"
            marginBottom={"2rem"}
          >
            <ChevronLeftIcon />
            Back
          </Text>
        )}
        {props.children}
      </Container>
    </VStack>
  );
};
