import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  InputGroup,
  Input,
  Button,
  Heading,
  VStack,
  Container,
  Text,
  useToast,
} from "@chakra-ui/react";

import axios, { AxiosError } from "axios";
import { baseURL } from "../../api";
export const ForgotPassword = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
  });
  // eslint-disable-next-line
  const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const onSubmit = async () => {
    const { email } = formValues;
    console.log(email.toLowerCase(), emailRegex.test(email.toLowerCase()))

    if (email.length === 0 || !emailRegex.test(email.toLowerCase())) {
      toast({
        title: "Invalid Email",
        description: "Please enter a valid email",
        status: "warning",
        duration: 3000,
        isClosable: true
      })
      return
    }

    try {
      setIsSubmitting(true);

      await axios.post(
        baseURL + "/auth/authAction/sendResetPassword",
        {
          email,
          url: window.location.protocol + "//" + window.location.host + "/login",
        },
      );

      toast({
        title: "Email sent.",
        description: "We've sent you an email to reset your password.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/");
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: "Error",
          description: `An error occured sending the password reset email.\n${error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      }
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <VStack
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={"100vh"}
    >
      <Container maxW="md">
        <form>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Heading marginBottom={"2rem"} size="lg">
              We’ll send you an email with instructions for resetting your
              password.
            </Heading>
            <InputGroup size="md" marginBottom={"1rem"}>
              <Input
                pr="4.5rem"
                type="text"
                placeholder="Email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
              />
            </InputGroup>
            <VStack>
              <Text
                textAlign="right"
                width="full"
                marginTop="0.5rem"
                marginBottom="1rem"
                color="blue.500"
              >
                <Link to="/login">I already have an account</Link>
              </Text>

              <Button
                isLoading={isSubmitting}
                colorScheme="blue"
                variant="solid"
                onClick={onSubmit}
                disabled={isSubmitting}
                loadingText="Loading"
                spinnerPlacement="start"
                type="button"
                w="full"
                marginTop={"1rem"}
              >
                {isSubmitting ? "sending..." : "Send Link"}
              </Button>
            </VStack>
          </div>
        </form>
      </Container>
    </VStack>
  );
};
